<template>
    <div>
        <v-dialog v-model="dialog">
            <v-date-picker color="primary" type="month" min="2021-08" v-model="date">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" @click="save">Choose</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>


<script>

export default {
    name: 'MonthCalendarPicker',
    data() {
        return {
            dialog: false,
            date: ''
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        save: function() {
            var date = this.date?this.date + "-01":this.date
            this.$emit('selected', date)
            this.close()
        }
    }
}

</script>